import React, { Fragment } from 'react'
import { Container, Box, Flex } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import ContentText from '@solid-ui-components/ContentText'
import Icon from '@solid-ui-components/ContentIcon'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const RoadMapBlock01 = ({ content: { collection, text = [] } }) => (
  <Container as={Reveal}>
    <Box sx={{ textAlign: 'center' }}>
      <ContentText content={text} />
    </Box>
    <Box>
      {collection.map(({ text, icon }, index) => (
        <Flex
          key={`rd-content-${index}`}
          sx={{
            justifyContent: 'left',
            alignItems: 'left',
            flexDirection: 'column',
            mb: [4, 3, null, null],
            textAlign: 'left'
          }}
        >
          <Box mb={3}>
            <Icon content={icon} size='md' mr={3} />
            <ContentText content={text?.[0]} sx={{ display: 'inline-block' }} />
          </Box>
          <Box>
            {text?.slice(1)?.map(txt => (
              <ContentText key={txt.text} content={txt} />
            ))}
          </Box>
        </Flex>
      ))}
    </Box>
    <Flex
      sx={{
        alignItems: `center`,
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          flexBasis: `1/2`,
          mt: [4, null, null, 0],
          ml: [null, null, null, 5]
        }}
      ></Box>
    </Flex>
  </Container>
)

export default WithDefaultContent(RoadMapBlock01)
